@import './../../../../scss/theme-bootstrap';

.product-quickshop-wrapper {
  .product-grid--filtered & {
    clear: none;
  }
}

.product-quickshop {
  margin: 0 auto;
  width: 100%;
  .sku-menu__container,
  .quantity-menu__container,
  .product-sku-price,
  .product-quickshop__auto-replenishment,
  .product-quickshop__attributes {
    border-bottom: 2px solid $color-black;
    padding: 15px 0;
    @include breakpoint($medium-up) {
      border: 0;
      padding: 0;
      margin-bottom: 15px;
    }
  }
  .quantity-menu__container {
    padding: 15px 0;
    border-bottom: none;
    @include breakpoint($medium-up) {
      padding: 0;
    }
  }
  .sku-menu__container {
    padding: 15px 0 0 0;
    border-bottom: none;
    @include breakpoint($medium-up) {
      padding: 0;
    }
  }
  .sku-menu__single,
  .product-sku-price {
    font-family: $font--avenir-light;
    font-size: 19px;
    line-height: 19px;
    &__value {
      display: block;
    }
  }
  .product-quickshop__attributes {
    @include breakpoint($medium-up) {
      margin: 20px 0 0 0;
    }
  }
  .product-quickshop__button {
    margin: 20px 0 0 0;
    @include breakpoint($medium-up) {
      margin: 0 0 12px 0;
    }
    .button {
      @include button-large--desktop;
      width: 100%;
      font-size: 19px;
    }
  }
  .product__button--shop-wholesale {
    margin-bottom: 15px;
  }
  .product-quickshop__auto-replenishment {
    text-align: center;
    @include breakpoint($medium-up) {
      text-align: $ldirection;
    }
  }
  .selectBox {
    width: 100%;
    color: $color-black;
    @include breakpoint($medium-up) {
      margin: 0 auto;
      text-align: $ldirection;
    }
    .touchevents & {
      font-size: 16px;
    }
  }
  @include breakpoint($medium-up) {
    .sku-menu__container,
    .quantity-menu__container {
      float: $ldirection;
      width: 48%;
    }
    .quantity-menu__container {
      max-width: 160px;
    }
    .sku-menu__container {
      margin-#{$rdirection}: 4%;
    }
    .sku-menu__single {
      position: relative;
      top: 13px;
    }
  }
}

.product-quickshop__product {
  @include breakpoint($medium-up) {
    @include pie-clearfix;
  }
}

.product-quickshop__body {
  @include pie-clearfix;
  padding: 20px 10px 0 10px;
  margin: 0 0 20px 0;
  @include breakpoint($medium-up) {
    padding: 0;
    margin: 0;
    &:after {
      display: none;
    }
  }
}

.product-quickshop__image {
  float: $ldirection;
  width: 30%;
  margin: 0 3.33333% 0 0;
  @include breakpoint($medium-up) {
    width: 42%;
    margin-#{$rdirection}: 2%;
    padding-top: 35px;
  }
  img {
    max-width: 100%;
  }
}

.product-quickshop__details {
  float: $ldirection;
  width: 66.66667%;
  padding: 0;
  @include breakpoint($medium-up) {
    float: $rdirection;
    width: 50%;
    margin-#{$rdirection}: 6%;
    padding-top: 30px;
  }
}

.product-quickshop__details-link {
  font-family: $font--avenir-light;
  color: $color-gray;
}

.product-quickshop__close {
  top: 11px;
  #{$rdirection}: 10px;
  .qs-overlay-cbox & {
    display: none;
  }
  @include breakpoint($medium-up) {
    top: 25px;
    #{$rdirection}: 40px;
    .icon {
      font-size: 24px;
    }
  }
}

.product-quickshop__headline {
  width: 80%;
  margin: 0 0 10px 0;
  @include breakpoint($medium-up) {
    width: 95%;
    margin: 0;
  }
}

.product-quickshop__description {
  margin: 0 0 10px 0;
  @include breakpoint($medium-up) {
    font-size: 19px;
    font-family: $font--avenir-light;
    margin-bottom: 5px;
  }
}

.product-quickshop__footer {
  clear: both;
  padding: 0 10px 15px 10px;
  @include breakpoint($medium-up) {
    position: relative;
    clear: none;
    padding: 20px 0;
    background: none;
    float: $ldirection;
    width: 55%;
  }
}

.product-quickshop__footer-container {
  border-top: 2px solid $color-black;
  @include breakpoint($medium-up) {
    border: 0;
  }
}

.product-quickshop__favorites {
  display: none;
  @include breakpoint($medium-up) {
    display: inline-block;
  }
  .product__button--add-favorites {
    font-size: 30px;
    margin-#{$rdirection}: 15px;
    &:hover {
      color: $color-link-hover;
    }
  }
}

.product-quickshop__social {
  display: none;
  @include breakpoint($medium-up) {
    display: inline-block;
  }
}

.waitlist-form__header {
  margin-bottom: 10px;
}

.waitlist-form__submit {
  height: 45px;
  margin-top: -4px;
}

.waitlist-overlay-cbox {
  padding: 0%;
  min-height: 320px;
  #cboxWrapper {
    min-height: 320px;
  }
  #cboxLoadedContent {
    padding: 0%;
    min-height: 320px;
  }
  .waitlist-form__container,
  .waitlist-form__success {
    margin: 3%;
  }
}

.product-add-to-waitlist {
  width: auto;
  padding: 10px 25px;
  min-width: 260px;
  font-size: 19px;
  @include breakpoint($small-down) {
    width: 100%;
  }
}

@include breakpoint($small-down) {
  .waitlist-form__email {
    width: 60%;
  }
}

.mpp-product-quickshop {
  &__extole {
    text-transform: uppercase;
  }
}
